import Loader from "components/Loader";
import React, { Fragment } from "react";

type IProps = {
  isLoading: boolean;
  children: React.ReactNode;
};
const DataLoaderOverlay = ({ isLoading, children }: IProps) => {
  return (
    <Fragment>
      {isLoading ? (
        <div className="h-[calc(100vh_-_90px)] w-screen overflow-hidden pointer-events-none z-30 relative p-0 m-0  bg-transparent">
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <Loader
              style={{
                height: "3rem",
                width: "3rem",
                color: "black",
                opacity: "0.7",
              }}
            />
          </div>
        </div>
      ) : (
        <>{children}</>
      )}
    </Fragment>
  );
};

export default DataLoaderOverlay;
