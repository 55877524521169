import { InferType } from "yup";
import {
  loginSchema,
  transactionSchema,
  fileUploadSchema,
  forgetPasswordSchema,
  resetPasswordSchema,
  checkoutSchema,
  kitUpdateploadSchema,
  labFileUploadSchema
} from "utils/validationSchema";
import { StripeCardNumberElement } from "@stripe/stripe-js";
export type LoginSchema = InferType<typeof loginSchema>;
export type TransactionSchema = InferType<typeof transactionSchema>;
export type FileUploadSchema = InferType<typeof fileUploadSchema>;
export type LabFileUploadSchema = InferType<typeof labFileUploadSchema>;
export type ForgetPasswordSchema = InferType<typeof forgetPasswordSchema>;
export type ResetPasswordSchema = InferType<typeof resetPasswordSchema>;
export type CheckoutSchema = InferType<typeof checkoutSchema>;
export type KitUpdateSchema = InferType<typeof kitUpdateploadSchema>;


export enum KEYS {
  USER = "user",
  TOKEN = "token_dg",
}
export type UserCredentials = {
  password: string;
  email: string;
};
export type UserPasswords = {
  new_password1: string;
  new_password2: string;
  token: any;
};
export type UserEmail = {
  email: string;
};
export type ResetPassword = {
  password: string;
  token: string;
};
export type UserData = {
  fname: string;
  lname: string;
  email: string;
  file: File;
  service_type: string;
};
export type PaymentInfo = {
  service_type: string;
  stripe_token: string;
};
export type CardInfo = {
  cardNumber: StripeCardNumberElement;
  cardHolderName: string;
};
export type UserActionsData = {
  userData: UserData;
  paymentInfo: PaymentInfo;
  cardInfo: CardInfo;
};
export interface Text {
  [key: string]: number;
}
export type Textheres = {
  Texthere: Text;
  Texthere1: Text;
};

export type Columns = {
  col1: string;
  col2: string;
};

export type ClientInfo = {
  name: string;
  email: string;
};
export type Option = {
  label: string;
  value: string | number;
};
export enum FormOptions {
  "Alabama" = "Alabama",
  "California" = "California",
}