import React from "react";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { LOGININITIALVALUES,PATHS } from "../../../constants";
import { loginSchema } from "../../../utils/validationSchema";
import { UserCredentials } from "types";

type IProps = {
  submitHandler: (arg: UserCredentials) => void;
  isLoading: boolean;
};

const Form = ({ submitHandler, isLoading }: IProps) => {
  const { handleSubmit, handleChange, values, touched, errors } = useFormik({
    initialValues: LOGININITIALVALUES,
    validationSchema: loginSchema,
    onSubmit: (values: UserCredentials) => {
      submitHandler({...values, email: values.email.toLowerCase()});
    },
  });
  return (
    <form
      className="w-full bg-[#FCFCFC] px-4 rounded md:shadow-md  max-h-full "
      noValidate
      onSubmit={handleSubmit}
    >
      <div className="mx-4">
        <h2 className="text-xl font-bold my-10 leading-normal pt-2">
        Welcome to the future of personalized wellness, based on your DNA.
        </h2>
        <Input
          placeholder="Email"
          name="email"
          error={touched.email && errors.email}
          className="mb-[19px]"
          onChange={handleChange}
          value={values.email}
        />
        <Input
          placeholder="*********"
          type="password"
          name="password"
          error={touched.password && errors.password}
          className=""
          onChange={handleChange}
          value={values.password}
        />

        <Button title={"Login"} type="submit" isLoading={isLoading} />
        <Link
          to={PATHS.FORGETPASSWORD}
          className="text-center text-[#898989] text-lg my-6 justify-center items-center flex font-helvetica"
        >
          Forgot password?
        </Link>
      </div>
    </form>
  );
};

export default Form;
