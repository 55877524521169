import img from "../../assets/Images/login_img.png";
import { useCallback,useEffect } from "react";
interface IProps{
    children:React.ReactNode
    className?:string
    margin?:string
}
const AuthLayout:React.FC<IProps> = ({children,className,margin}) => {

  const toggleBodyScrolling = useCallback(() => {
    const body = document.querySelector("body");
    if (body) {
      body.style.color = "bg-main";
    }
  }, []);

  useEffect(() => {
    toggleBodyScrolling();
  }, [toggleBodyScrolling]);

  return (
    <div className="bg-main  overflow-y-hidden py-auto">
      <div className="flex flex-col sm:mt-12" style={{ overflow: "hidden" }}>
        <div className="flex-1 w-full flex md:flex-row justify-center items-center">
          <div className={`hidden lg:w-1/2 lg:block mb-4  ${margin}`}>
            <img
              src={img}
              alt="img"
              className={`object-cover rounded max-w-[400px] xl:ml-[135px]`}
            />
          </div>
          <div className={`${className}`}>{children}</div>
        </div>
      </div>
    </div>
  );
};
// "max-w-[440px] p-4 max-h-[520px] h-full flex items-center justify-center";
export default AuthLayout;
