import React, { Fragment } from "react";
import { Navigate } from "react-router-dom";
import { PATHS } from "../../constants";
interface IProps {
  children: React.ReactNode;
  isAdmin: boolean;
}
export const ProtectedRoutes = ({ children, isAdmin }: IProps) => {
  if (!isAdmin) {
    return <Navigate to={PATHS.DEFAULT} />;
  }
  return <Fragment>{children}</Fragment>;
};
