
import AuthLayout from "components/AuthLayout";
import Form from "./components/Form";
import { useAuth } from "hooks/useAuth";

const Login = () => {
  const { loginUser, isLoading } = useAuth();
  return (
    <AuthLayout className="max-w-[440px] p-4 max-h-[520px] h-full flex items-center justify-center">
      <Form submitHandler={loginUser} isLoading={isLoading} />
    </AuthLayout>
  );
};

export default Login;
