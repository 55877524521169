import Accordion from "pages/Categories/components/Accordion";
import { useReportRecords } from "hooks/useReportRecords";
import { useParams } from "react-router-dom";
import DataLoaderOverlay from "components/DataLoaderOverlay";
import { useEffect} from "react";
import Loader from "components/Loader";
import Summary from "./components/Summary";
import Button from "../../components/Button";

const Categories = () => {
  const { id } = useParams();
  const { isLoading, reportData, fetchReportRecords, setSelectedCategory, fetchClientReport} =
    useReportRecords();
  
  useEffect(() => {
    fetchReportRecords(id!);
    setSelectedCategory(undefined);
  }, [fetchReportRecords, id, setSelectedCategory]);
  
  const reportGenerate= async()=>{
    const url = await fetchClientReport(id!)
    window.open(url, "_blank");
  }
  return (
    <DataLoaderOverlay isLoading={isLoading}>
      <div className="flex-col justify-center h-[calc(100vh_-_90px)] py-14 bg-main">
        <div className="px-[40px] w-full">
          <Button
            title="Generate Report"
            onClick={()=>{reportGenerate ()}}
            className="rounded-md px-[30px] text-xl bg-white transition-all duration-200 hover:main font-sans font-medium max-w-[200px] float-right"
          />
        </div>
        <div className="px-[40px] w-full py-[64px] mt-5">
          {reportData ? <Accordion data={reportData} /> : <Loader />}
          {reportData ? <Summary /> : <Loader />}
        </div>
      </div>
    </DataLoaderOverlay>
  );
};

export default Categories;
