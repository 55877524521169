
import { useEffect } from "react";
import Footer from "../../components/Footer"
import Checkout from "./components/Checkout";
import { usePaymentAndUpload } from "hooks/usePaymentAndUpload";
const OrderKits = () => {
    useEffect(() => {
        const script = document.createElement('script');
      
        script.src = "//tinder.thrivecart.com/embed/v1/thrivecart.js";
        script.id = 'tc-designergenes-1-I5Z067'
        script.async = true;
      
        document.body.appendChild(script);
      
        return () => {
          document.body.removeChild(script);
        }
      }, []);
      
    const { processCheckoutPayment, isLoading,kit_credits,processCheckoutOnly,getUserData} =
    usePaymentAndUpload();
    return (
        <div className="bg-main">
            <div className="flex flex-col sm:pt-12 mx-4 items-center justify-center pt-2">
                <div className="mt-8 sm:w-9/12 w-full">
                    <Checkout 
                    processCheckoutPayment={processCheckoutPayment}
                    isLoading={isLoading}
                    credits={kit_credits}
                    processCheckoutOnly={processCheckoutOnly}
                    getUserData={getUserData}
                    />
                </div>
            </div>
            <div className="mt-16 pb-12">
                <Footer />
            </div>
        </div>
    );
};

export default OrderKits;
