import React from "react";
import Approute from "./component/Approute";
import Authroute from "./component/Authroute";
import MainNavbar from "components/Header";
import { useUser } from "state/user/hooks";
import { useLocation, Route, Routes } from "react-router-dom";  // Add Route and Routes from react-router-dom
import UpdatePasswordPage from '../pages/UpdatePassword';  // Import the UpdatePassword page

const MyRoutes = () => {
  const { isAuthenticated, user } = useUser();
  const location = useLocation();

  return (
    <div
      className={`root ${"bg-main"} h-screen`}
    >
      <MainNavbar isLoggedIn={isAuthenticated} user={user} />

      {/* Authenticated routes or specific open routes */}
      {isAuthenticated || location.pathname === '/register' || location.pathname === '/lab-reports-upload' ? (
        <Routes>
          {/* Protected routes */}
          <Route path="/update-password" element={<UpdatePasswordPage />} />  {/* New Update Password route */}
          <Route path="/*" element={<Approute isAdmin={!user?.isClient} />} />
        </Routes>
      ) : (
        <Authroute />
      )}
    </div>
  );
};

export default MyRoutes;
