import React from "react";
import upload from "assets/Icons/Upload.png";

type TProps = {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedFile: FileList | null;
  errors: string | undefined | boolean;
};

function FileInput({ onChange, selectedFile, errors }: TProps) {
  return (
    <>
      <input
        type="file"
        id="file"
        className="hidden"
        accept=".txt"
        name="inputFile"
        onChange={onChange}
        multiple
      />
      <label
        htmlFor="file"
        className="flex px-3 py-3 w-full focus:outline-none bg-[#F4F4F4] cursor-pointer text-center my-5 max-w-[740px] h-[70px] font-helvetica "
      >
        <img
          src={upload}
          className="w-8 h-8 justify-center"
          alt="uploaded-file"
        />
        <p className="ml-4 my-2 font-sans">
          {selectedFile
            ? `Reports Selected: ${selectedFile.length}` // Add space and correct string concatenation
            : "Select Your File"}
        </p>
      </label>
      <div className="mb-2">
        <label className="font-sans text-sm text-red-700">
          {errors}
        </label>
      </div>
    </>
  );
}

export default FileInput;
