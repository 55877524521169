import { createSlice } from "@reduxjs/toolkit";
import { UpdateUserType, UserSliceType } from "./types";

const initialState: UserSliceType = {
  isAuthenticated: false,
  user: undefined,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserAction: (
      state,
      action: {
        payload: UserSliceType;
      }
    ) => ({
      ...state,
      ...action.payload,
    }),
    updateUserAction: (
      state,
      action: {
        payload: UpdateUserType;
      }
    ) => ({
      ...state,
      user: {
        ...state.user!,
        ...action.payload,
      },
    }),
  },
});

export const { setUserAction, updateUserAction } = userSlice.actions;

export default userSlice.reducer;
