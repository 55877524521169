import React from "react";
import Input from "components/Input";
import Button from "components/Button";
import { useFormik } from "formik";
import { RESETINITIALVALUES } from "constants/index";
import { resetPasswordSchema } from "utils/validationSchema";
import { useSearchParams } from "react-router-dom";
import { ResetPassword } from "types";

type IProps = {
  changePassword: (arg: ResetPassword) => void;
  isLoading: boolean;
};

const Form = ({ changePassword, isLoading }: IProps) => {
  const [searchParams] = useSearchParams();
  const { handleSubmit, handleChange, values, touched, errors } = useFormik({
    initialValues: RESETINITIALVALUES,
    validationSchema: resetPasswordSchema,
    onSubmit: (values) => {
      console.log("Form submitted", values);
      const token = searchParams.get("token");
      console.log("Token:", token);
      if (token)
        changePassword({
          password: values.new_password1,
          token,
        });
    },
  });
  return (
    <form
      className="w-full bg-[#FCFCFC] px-4 rounded md:shadow-md  max-h-full  pb-5 "
      noValidate
      onSubmit={handleSubmit}
    >
      <div className="mx-4">
        <h2 className="text-xl font-bold my-10 leading-normal pt-2">
          Reset your Password
        </h2>
        <Input
          placeholder="New Password"
          name="new_password1"
          type="password"
          error={touched.new_password1 && errors.new_password1}
          className="mb-[19px]"
          onChange={handleChange}
          value={values.new_password1}
        />
        <Input
          placeholder="Confirm Password"
          type="password"
          name="new_password2"
          error={touched.new_password2 && errors.new_password2}
          className=""
          onChange={handleChange}
          value={values.new_password2}
        />

        <Button
          title={"Submit"}
          type="submit"
          isLoading={isLoading}
          className="mb-4"
        />
      </div>
    </form>
  );
};

export default Form;
