import { useEffect, useMemo } from "react";
import Table from "./components/Table";
import { useClientsManager } from "hooks/useClientManager";
import { useOrderKitsData } from "hooks/useOrderKits";
import { useLoading } from "state/loading/hooks";
import DataLoaderOverlay from "components/DataLoaderOverlay";
import Button from "components/Button";
import { useUser } from "state/user/hooks";

const KitsStatus = () => {
  const { fetchOrderKits, orderKitsData, updateOrderKitStatus } = useOrderKitsData();
  const { user } = useUser();
  const { fetchThriveCart } = useClientsManager();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await updateOrderKitStatus()
        await fetchOrderKits();
        if (user?.is_SuperAdmin) {
          await fetchThriveCart();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [updateOrderKitStatus, fetchOrderKits, fetchThriveCart, user?.is_SuperAdmin]);

  const { isLoading } = useLoading();
  const tableData = useMemo(() => {
    return orderKitsData?.map((orderKit: any) => {
      return {
        col1: orderKit.order_date,
        col2: orderKit.fname + " " + orderKit.lname,
        col3: orderKit.email,
        col4: orderKit.kit_status,
        col5: orderKit.tracking_no,
        col6: orderKit.kit_file_url
      };
    }) || [];
  }, [orderKitsData]);

  return (
    <DataLoaderOverlay isLoading={isLoading}>
      <div className="px-[40px] w-full mt-14">
        <div className="w-fit ml-auto">
          <Button
            title={"Update Status"}
            type="button"
            onClick={updateOrderKitStatus}
            isLoading={isLoading}
            className="mb-4 bg-[#fffbfb] text-black"
          />
        </div>
        {tableData?.length ? <Table data={tableData} /> : null}
      </div>
    </DataLoaderOverlay>
  );
};

export default KitsStatus;
