// @ts-ignore
import React, { Fragment } from "react";
import { ReportData } from "state/records/types";
import CollapseItem from ".././Collapse/index";

type IProps = {
  data: ReportData;
};

const Accordion = ({ data }: IProps) => {
  let orderedCategories = [];
  if (data.categories && data.categories.length > 0) {
    orderedCategories = [...data.categories].sort((a, b) => a.order - b.order);
  }
  return (
    <>
      {data.categories && data.categories.length > 0 && 
        <Fragment>
          {Object.entries(orderedCategories).map(([key, value], index) => (
            <CollapseItem
              key2={value.category_name}
              key={key}
              index={index}
              value={value}
              data={data}
              category_id={value.id}
            />
          ))}
        </Fragment>
      }
    </>
  );
};

export default Accordion;
