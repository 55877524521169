import { api } from "config";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { useClients } from "state/clients/hooks";
import { useLoading } from "state/loading/hooks";

export const useClientsManager = () => {
  const { isLoading, setIsLoading } = useLoading();
  const {
    coachClients,
    setCoachClients,
    clientDetails,
    setClientDetails,
    selectedClient,
    setSelectedClient,
  } = useClients();

  const fetchClientReports = useCallback(
    async (clientID: string | number) => {
      try {
        setIsLoading(true);
        const { data } = await api.post("client/reports/", {
          client_id: clientID,
        });
        if (data.success) setClientDetails(data.data);
      } catch (e: any) {
        toast.error(e);
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, setClientDetails]
  );

  const fetchCoachClients = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await api.get("coach/clients/");
      if (data.success) setCoachClients(data.data);
      // console.log(data.data);
    } catch (e: any) {
      toast.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading, setCoachClients]);
  const fetchThriveCart = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await api.get("get/thrivecart/");
      if (data.success) {
        console.log(data.data); // Log thrivecart data
        return data.data; // Return thrivecart data
      }
    } catch (e: any) {
      toast.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading]);
  return {
    fetchClientReports,
    isLoading,
    fetchCoachClients,
    fetchThriveCart,
    coachClients,
    clientDetails,
    selectedClient,
    setSelectedClient,
  };
};
