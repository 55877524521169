import React from "react";
import Input from "components/Input";
import Button from "components/Button";
import { useFormik } from "formik";
import { FORGETPASSWORD } from "constants/index";
import { forgetPasswordSchema } from "utils/validationSchema";
import { UserEmail } from "types";

type IProps = {
  submitHandler: (arg: UserEmail) => void;
  isLoading: boolean;
};

const Form = ({ submitHandler, isLoading }: IProps) => {
  const { handleSubmit, handleChange, values, touched, errors } = useFormik({
    initialValues: FORGETPASSWORD,
    validationSchema: forgetPasswordSchema,
    onSubmit: (values) => {
      submitHandler(values);
    },
  });
  return (
    <form
      className="w-full bg-[#FCFCFC] px-4 rounded md:shadow-md  max-h-full  pb-10 sm:mt-1 mt-10"
      noValidate
      onSubmit={handleSubmit}
    >
      <div className="mx-4">
        <h2 className="text-xl font-bold my-10 leading-normal pt-2">
          Confirm Your Email
        </h2>

        <Input
          placeholder="Your Email"
          name="email"
          error={touched.email && errors.email}
          className=""
          onChange={handleChange}
          value={values.email}
        />

        <Button title={"Submit"} type="submit" isLoading={isLoading} />
      </div>
    </form>
  );
};

export default Form;
