import { createSlice } from "@reduxjs/toolkit";
import { RecordsSliceType, ReportData } from "./types";

const initialState: RecordsSliceType = {
  reportData:[],
  reportSummary:undefined,
  report:undefined,
  selectedCategory: undefined,
  selectedNotes: undefined,
};

const recordsSlice = createSlice({
  name: "records",
  initialState,
  reducers: {
    setReportDataStateAction: (
      state,
      action: {
        payload: ReportData;
      }
    ) => ({
      ...state,
      reportData: action.payload,
    }),
    setSelectedCategoryStateAction: (
      state,
      action: {
        payload: string | undefined;
      }
    ) => ({
      ...state,
      selectedCategory: action.payload,
    }),
    setSelectedNotesStateAction: (
      state,
      action: {
        payload: string | undefined;
      }
    ) => ({
      ...state,
      selectedNotes: action.payload,
    }),
    setReportSummaryStateAction: (
      state,
      action: {
        payload: string | undefined;
      }
    ) => ({
      ...state,
      reportSummary: action.payload,
    }),
    setReportStateAction: (
      state,
      action: {
        payload: string | undefined;
      }
    ) => ({
      ...state,
      report: action.payload,
    }),
  },
});

export const {
  setReportDataStateAction,
  setSelectedCategoryStateAction,
  setSelectedNotesStateAction,
  setReportSummaryStateAction,
  setReportStateAction,
} = recordsSlice.actions;

export default recordsSlice.reducer;
