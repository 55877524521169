// ClientNameAutocomplete.js
import React, { useRef, useEffect, useState } from "react";
import Input from "components/Input";
import { ClientInfo } from "types";

interface IProps {
  searchValue: string;
  setSearchValue: (value: string) => void;
  errors: string | false | undefined;
  data: ClientInfo[];
  onSelect: (arg: ClientInfo) => void;
}

const ClientNameAutocomplete: React.FC<IProps> = ({
  searchValue,
  setSearchValue,
  errors,
  data,
  onSelect,
}) => {
  const [suggestions, setSuggestions] = useState<ClientInfo[]>([]);
  const selectRef = useRef<HTMLDivElement>(null);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchValue(value);
    let matches: ClientInfo[] = [];
    if (value.length > 0) {
      const regex = new RegExp(`${value}`, "i"); // Case-insensitive regex
      matches = data.filter((item: ClientInfo) => regex.test(item.name));
    }
    setSuggestions(matches);
  };

  const handleOutsideClick = (event: any) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setSuggestions([]);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className={["relative"].join(" ")}>
      <Input
        type="text"
        name="clientName"
        value={searchValue}
        placeholder="Client Name"
        error={errors}
        onChange={onChangeHandler}
      />
      {suggestions.length > 0 && (
        <ul className="mb-0 list-none rounded-b-md absolute bg-field border border-gray w-full max-h-[120px] overflow-y-auto p-0  z-20">
          {suggestions.map((value, key) => (
            <li
              key={key}
              className="text-black font-sans font-bold px-4 py-2 border-b-gray border-b-[1px] "
              onClick={() => {
                setSearchValue(value.name);
                setSuggestions([]);
                onSelect(value);
              }}
            >
              {value.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ClientNameAutocomplete;
