// @ts-ignore
import {useState } from "react";
import { Collapse } from "react-collapse";
import AccordionItems from "../Accordion/Items";
import { TotalRiskCalculator } from "utils";

import add from "assets/Icons/plus.png";
import close from "assets/Icons/minus.png";
type IProps = {
    index:number;
    data: any;
    key2:string;
    value: { [key: string]: {} };
    category_id: number;
};
const CollapseItem = ({ index, data, key2, value, category_id }: IProps) => {
    const [open, setOpen] = useState(false);
    const [openIndex, setOpenIndex] = useState(-1);
    const onClick = (index: number) => {
        setOpenIndex(index === openIndex ? -1 : index);
        setOpen(!open);
      }
    const getPointsColor = (data:any, title:any) =>{
        if(data <=25) {
          return "text-[#07bc0c]"
        }
        else if(data >25 && data <=39) return "text-[#f1c40f]"
        return "text-[#e74c3c]"
    }
    const totalRiskPercentage = parseFloat(TotalRiskCalculator(data, category_id)).toFixed(0);
    return (
      <div className="pt-[10px]" key={index}>
        <div
          className="bg-[#FCFCFC] py-[15px] lg:px-10 flex justify-between px-3 items-center cursor-pointer"
          onClick={() => {
            onClick(index);
          }}
        >
          <p className="text-lg">
            {key2}
            <span className={`${getPointsColor(totalRiskPercentage, key2)} text-[18px] font-helvetica ml-4`}>
              {totalRiskPercentage}%
            </span>
          </p>
          <div className="flex justify-items-end">
            <img
              src={open ? close : add}
              alt="arrow"
              className="max-w-[70px] max-h-[65px] justify-self-end"
            />
          </div>
        </div>
        <Collapse isOpened={open}>
          <div className="bg-[#FCFCFC] border-b-2 border-field">
            {Object.entries(data.subcategories).map(([key3, value], index) => {
              return category_id === value.category &&
                <AccordionItems
                  key={index}
                  category={key2}
                  title={value.sub_category_name}
                  data={data.records.filter((item) => item.subcategory === value.id)}
                />
            })}
          </div>
        </Collapse>
      </div>
    );
}
export default CollapseItem;
