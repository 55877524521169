import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "assets/Icons/logo.png";
import menu from "assets/Icons/menu.png";
import { Links, UserLinks } from "constants/index";
import { UserType } from "state/user/types";
import { useAuth } from "hooks/useAuth";

type IProps = {
  isLoggedIn: boolean;
  user: UserType | undefined;
};
const MainNavbar = ({ isLoggedIn, user }: IProps) => {
  const [open, setOpen] = useState(false);
  const access = isLoggedIn ? UserLinks : [];
  const isAdmin = !user?.isClient;
  const nagivationLinks = isAdmin&&isLoggedIn ? Links : access;
  const { logoutUser } = useAuth();
  const handleMenuClick = () => {
    setOpen(!open);
    toggleBodyScrolling();
  };
  const toggleBodyScrolling = () => {
    const body = document.querySelector("body");
    if (body) {
      body.style.overflow = open ? "auto" : "hidden";
    }
  };
  return (
    <div className="bg-[#FCFCFC] h-fit">
      <div className="flex items-center justify-between py-4 mobile:pt-4">
        <div className="flex items-center ">
          <Link to="/">
            <img src={logo} alt="Logo" className="mx-2 h-9" />
          </Link>
        </div>
        <div className="py-2 hidden md:flex ">
          {nagivationLinks.map((link) => (
            <Link
              key={link.name}
              to={link.link}
              className="block py-2 px-10 duration-500 text-[20px]"
            >
              {link.name}
            </Link>
          ))}
        </div>
        <div className={`${isLoggedIn ? "block" : "hidden"} w-[220px] float-right `}>
          <button
            onClick={logoutUser}
            className={`py-2 duration-500 md:flex hidden float-right mr-4`}
          >
            {" "}
            Log Out
          </button>
        </div>
        <div
          onClick={handleMenuClick}
          className={` ${
            isLoggedIn
              ? "text-xl md:hidden cursor-pointer float-right ml-3"
              : "hidden"
          }`}
        >
          {<img src={menu} alt="menu" className="h-8 w-8" />}
        </div>
      </div>
      {open && (
        <div className={`md:hidden  px-6 w-full bg-[#FCFCFC] pb-3`}>
          <div className="mt-7">
            {nagivationLinks.map((link) => (
              <>
                <Link
                  key={link.name}
                  onClick={handleMenuClick}
                  to={link.link}
                  className="block py-3 duration-500  border-[#4d4d4d]  border-b pl-4 mt-4"
                >
                  {link.name}
                </Link>
              </>
            ))}
          </div>
          {isLoggedIn ? (
            <Link
              to="/"
              onClick={logoutUser}
              className="block py-3 duration-500  pl-4 mt-4 mb-3 border-[#4d4d4d]  border-b"
            >
              Log Out
            </Link>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default MainNavbar;
