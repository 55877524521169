import { useCallback } from "react";
import { AxiosError } from "axios";
import { api } from "config";
import { PATHS } from "constants/index";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useLoading } from "state/loading/hooks";
import { useUser } from "state/user/hooks";
import { UserType } from "state/user/types";
import { ResetPassword, UserCredentials, UserEmail } from "types";

export const useAuth = () => {
  const { isLoading, setIsLoading } = useLoading();
  const { setUser } = useUser();
  const navigate = useNavigate();

  // const getUserCredits = useCallback(async (key: string) => {
  //   const { data } = await api.get("user/details/", {
  //     headers: {
  //       Authorization: "Token " + key,
  //     },
  //   });
  //   return data.data.credits;
  // }, []);

  const loginUser = useCallback(
    async (credentials: UserCredentials) => {
      try {
        setIsLoading(true);
        const { data } = await api.post("login/", credentials);
        const user: UserType = {
          key: data.key,
          id: data.id,
          name: data.first_name + data.last_name,
          isClient: data.is_client,
          lname: data.last_name,
          fname: data.first_name,
          credits: data.credits,
          kit_credits:data.kit_credits,
          is_SuperAdmin:data.is_superuser
        };
        toast.success("Login Successful.");
        setUser({ user, isAuthenticated: true });
      } catch (e) {
        if (e instanceof AxiosError)
          toast.error(e.response?.data.error[0].non_field_errors[0]);
        else toast.error("Some error has occured! Please try again.");
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, setUser]
  );
  const logoutUser = useCallback(() => {
    toast.success("Logout Successful.");
    setUser({ user: undefined, isAuthenticated: false });
  }, [setUser]);

  const resetPassword = useCallback(
    async (email: UserEmail) => {
      try {
        setIsLoading(true);
        await api.post("/password/reset/", email);
        toast.success("Email Sent Successfully.");
        navigate(PATHS.LOGIN);
      } catch (e) {
        if (e instanceof AxiosError)
          toast.error(e.response?.data.error[0].email[0]);
        else toast.error("Some error has occurred! Please try again.");
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, navigate]
  );
  const changePassword = useCallback(
    async (credentials: ResetPassword) => {
      try {
        setIsLoading(true);
        await api.post(
          `/password/reset/confirm/`,
          credentials
        );
        toast.success("Password Changed Successfully.");
        navigate(PATHS.LOGIN);
      } catch (e) {
        toast.error("Some error has occurred! Please try again.");
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, navigate]
  );

  return {
    loginUser,
    logoutUser,
    isLoading,
    resetPassword,
    changePassword,
  };
};
