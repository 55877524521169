import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "state/store";
import { setUserAction, updateUserAction } from ".";
import { UpdateUserType, UserSliceType } from "./types";
import { useCallback, useEffect } from "react";
import {
  deleteUserInfoFromLocalStorage,
  saveUserInfoInLocalStorage,
  getUserInfoFromLocalStorage,
} from "libs/cache";

export const useUser = () => {
  const { isAuthenticated, user } = useSelector<RootState, UserSliceType>(
    (state) => state.user
  );
  const dispatch = useAppDispatch();

  const setUser = useCallback(
    (payload: UserSliceType) => {
      dispatch(setUserAction(payload));
      if (payload.user) saveUserInfoInLocalStorage(payload.user);
      else deleteUserInfoFromLocalStorage();
    },
    [dispatch]
  );
  const updateUser = useCallback(
    (payload: UpdateUserType) => {
      if (user) {
        dispatch(updateUserAction(payload));
        saveUserInfoInLocalStorage({
          ...user,
          ...payload,
        });
      }
    },
    [dispatch, user]
  );

  useEffect(() => {
    const userInfo = getUserInfoFromLocalStorage();
    if (userInfo && !user) setUser({ user: userInfo, isAuthenticated: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isAuthenticated,
    user,
    setUser,
    updateUser,
  };
};
