import { memo } from "react";
import { useTable } from "react-table";
import { ORDERKITSTABLE as columns } from "constants/index";
import { Link } from "react-router-dom";
type IProps = {
    data: any
};
const Table = ({ data }: IProps) => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({ columns, data });
  
    return (
        <div className="overflow-x-auto w-full sm:w-9/12 mx-auto">
            <table
                {...getTableProps()}
                className="font-helvetica mt-4 mb-4 w-full">
                <thead>
                    {headerGroups.map((headerGroup: any) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any, cellIndex: number) => (
                                <th
                                    {...column.getHeaderProps()}
                                    className={`border border-gray-400 px-4 py-2 text-sm text-left`}
                                >
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row: any, index: number) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell: any, cellIndex: number) => (
                                    <td
                                        {...cell.getCellProps()}
                                        className="border border-black px-4 py-2 text-sm">
                                       {cellIndex === 4 ? row.values.col5 ? <Link className="hover:underline" target="_blank" to={row.values.col5}>{row.values.col5}</Link> : '-' :
                                        cellIndex === 5 ? row.values.col6 ? <Link className="hover:underline" target="_blank" to={row.values.col6}>Report</Link> : '-' :
                                       cell.render('Cell')}
                                    </td>
                                ))}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}
export default memo(Table);
