import AuthLayout from "components/AuthLayout";
import Form from "./components/Form";
import { useAuth } from "hooks/useAuth";

const ForgetPassword = () => {
  const { resetPassword, isLoading } = useAuth();

  return (
    <AuthLayout className="w-[400px] p-4 max-h-[520px] h-full flex items-center ">
      <Form submitHandler={resetPassword} isLoading={isLoading} />
    </AuthLayout>
  );
};

export default ForgetPassword;
