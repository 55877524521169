import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "state/store";
import { setOrderKitsDataStateAction, updateKitDetailAction } from ".";
import { OrderKitsData, OrderKitsSliceType } from "./types";
import { useCallback } from "react";

export const useOrderKits = () => {
  const { orderKitsData } = useSelector<RootState, OrderKitsSliceType>(
    (state) => state.orderKits
  );
  const dispatch = useAppDispatch();

  const setOrderKitsData = useCallback(
    (payload: OrderKitsData[]) => {
      dispatch(setOrderKitsDataStateAction(payload));
    },
    [dispatch]
  );

  const updateKitDetail = useCallback(
    (updatedKit: OrderKitsData) => {
      dispatch(updateKitDetailAction(updatedKit));
    },
    [dispatch]
  );

  return {
    orderKitsData,
    setOrderKitsData,
    updateKitDetail,
  };
};


