import React from "react";
import "./style.css";
interface IProps {
  src?: string;
}

const SubLevelVideo: React.FC<IProps> = ({ src }) => {
  return (
    <div className="video-container my-3 mx-auto">
      <iframe src={src} className="video-iframe" width="100%" height="100%" />
    </div>
  );
};

export default SubLevelVideo;
