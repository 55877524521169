import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OrderKitsSliceType, OrderKitsData } from "./types";

const initialState: OrderKitsSliceType = {
  orderKitsData: undefined,
};

const orderKitsSlice = createSlice({
  name: "orderKits",
  initialState,
  reducers: {
    setOrderKitsDataStateAction: (
      state,
      action: PayloadAction<OrderKitsData[]>
    ) => {
      state.orderKitsData = action.payload;
    },
    updateKitDetailAction: (
      state,
      action: PayloadAction<OrderKitsData>
    ) => {
        const updatedOrderKitsData = state.orderKitsData?.map((kit) => {
            if (kit.email === action.payload.email) {
            return action.payload;
            }
            return kit;
        });

      state.orderKitsData = updatedOrderKitsData;
    },
  },
});

export const {
  setOrderKitsDataStateAction,
  updateKitDetailAction,
} = orderKitsSlice.actions;

export default orderKitsSlice.reducer;
