import React, { useState, useEffect } from "react";
import { useReportRecords } from "hooks/useReportRecords";
import { useParams } from "react-router-dom";
import add from "assets/Icons/plus.png";
import close from "assets/Icons/minus.png";
import { Collapse } from "react-collapse";
import { useUser } from "state/user/hooks";
import { toast } from "react-toastify";

const Summary = () => {
  const { id } = useParams();
  const { user } = useUser();
  const isClient = user?.isClient;

  const { fetchSummaryReportRecords, reportSummary, setReportSummary } =
    useReportRecords();
  const [text, setText] = useState(reportSummary)
  const [open, setOpen] = React.useState(false);

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  };
  const handleSave = (e: any) => {
    e.preventDefault();
    fetchSummaryReportRecords(id!, text!);
    toast.success("Report Summary Changed Successfully.");
    handleClose();
    setReportSummary(text!);
  };
  const handleClose = () => {
    setOpen(false);
  };
    useEffect(() => {
      setText(reportSummary);
    }, [reportSummary]);
  return (
    <div className="my-4">
      <div
        className="bg-[#FCFCFC] py-[15px] lg:px-10 flex justify-between px-3 items-center cursor-pointer "
        onClick={() => {
          setOpen(!open);
        }}
      >
        {" "}
        <p className="text-lg">Summary</p>
        <div className="flex justify-items-end">
          <img
            src={open ? close : add}
            alt="arrow"
            className="max-w-[70px] max-h-[65px] justify-self-end"
          />
        </div>
      </div>
      <Collapse isOpened={open}>
        <div className="bg-[#FCFCFC] border-b-2 border-field mb-5">
          <div className="items-center border-t-2 border-field lg:px-14 py-2 px-3">
            <form onSubmit={handleSave}>
            <textarea
              name="summary"
              id="summary"
              cols={30}
              value={text}
              onChange={handleTextChange}
              className="w-full min-h-[240px] border-2 border-field rounded-md p-2 mt-4"
            ></textarea>

            <div className="flex justify-center gap-x-4">
             {!isClient && <button
                className="rounded-md px-10 py-3 text-xl bg-main transition-all duration-200 hover:main font-sans font-medium"
              type="submit"
             >
                Update
              </button>}
            </div>
            </form>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default Summary;
