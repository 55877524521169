import React, { useState } from 'react';
import Input from "components/Input";
import Button from "components/Button";
import { getUserInfoFromLocalStorage } from 'state/user/utils';  // Assuming this is where the helper functions are located

const BASE_URL = process.env.REACT_APP_PUBLIC_BASE_URL;

async function updatePassword(currentPassword: string, newPassword: string) {
    // Retrieve the token from local storage
    const token = localStorage.getItem('token_dg');  // Fetching the correct token


    if (!token) {
        return { success: false, message: 'User not authenticated' };
    }

    const endpoint = `${BASE_URL.replace(/\/+$/, '')}/update-password/`;

    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,  // Use the token in the header
        },
        body: JSON.stringify({
            current_password: currentPassword,
            new_password: newPassword,
        }),
    });

    const result = await response.json();
    if (response.ok) {
        console.log('Password updated:', result);
        return { success: true, message: 'Password updated successfully' };
    } else {
        console.error('Error:', result);
        return { success: false, message: result.error || 'An error occurred' };
    }
}

const UpdatePasswordForm: React.FC = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            setMessage('New password and confirmation do not match');
            return;
        }

        const result = await updatePassword(currentPassword, newPassword);
        setMessage(result.message);
    };

    return (
    <form
        className="w-full bg-[#FCFCFC] px-4 rounded md:shadow-md max-h-full"
        noValidate
        onSubmit={handleSubmit}
    >
        <div className="mx-4">
            <h2 className="text-xl font-bold my-10 leading-normal pt-2">
                Update your password to ensure account security.
            </h2>
            <div>
                <Input
                    type="password"
                    placeholder='Current Password'
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    className="mb-[19px]"
                    required
                />
            </div>
            <div>
                <Input
                    type="password"
                    placeholder='New Password'
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="mb-[19px]"
                    required
                />
            </div>
            <div>
                <Input
                    type="password"
                    placeholder='New Password Confirmation'
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="mb-[19px]"
                    required
                />
            </div>
            <Button
                type="submit"
                title={"Submit"}
                className="mb-4"
            />
            {message && <p className="text-center text-red-500 text-sm mt-4">{message}</p>}
        </div>
    </form>
    );
};

export default UpdatePasswordForm;
