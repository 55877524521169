import { sortString } from 'utils';
import {SINGLEPAGECATEGORIES}from '../constants/categoriesDetailData';
import {SUBLEVELVIDEOS}from '../constants/index';
export const isSinglePageCategory = (category: string) => {
  return (
    SINGLEPAGECATEGORIES.filter(
      (item) => item.toLowerCase() === category.toLowerCase()
    ).length > 0
  );
};

export const points = (data: any) => {
  let total_risk_value = 0;
  let total_points = 0
  //console.log(data);
  
  data.forEach((item: any) => {
    //console.log(item);

    let risk_value=typeof item.micronutrient.risk_value.find(value => sortString(value.risk) === sortString(item.risk_value)) !== 'undefined' 
      ? item.micronutrient.risk_value.find(value => sortString(value.risk) === sortString(item.risk_value)).value 
      : 0

    total_risk_value+=(risk_value > 2 ? 2 : risk_value)*0.50

    total_points+=1;
  });

  return `${((total_risk_value/total_points) * 100)}`;
};
export const pointsColor = (data: any, title:any) => {
  if(title !== "WORKOUT RESPONSE"){
    if(data <=25) return "text-[#07bc0c]"
    else if(data >25 && data <=39) return "text-[#f1c40f]"
    return "text-[#e74c3c]"
  }
  else{
    if(data <=49) return "text-[#000]"
    return "text-[#07bc0c]"
  }
}
export const pointsSubLevelColor = (data: any, title:any) => {
  if( title !== 'SHORT BURST POWER' && title !== 'ENDURANCE' && title !== 'STRENGTH TRAINING' && title !=='MUFAS'){
    if(data <=25) return "text-[#07bc0c]"
    else if(data >25 && data <=39) return "text-[#f1c40f]"
    return "text-[#e74c3c]"
  }
  else{
    if(data <=49) return "text-[#000]"
    return "text-[#07bc0c]"
  }
}
export function findVideoLinkByName(nameToCheck: string) {
  const video = SUBLEVELVIDEOS.find((video) => video.name === nameToCheck);
  return video ? video.link : null;
}  