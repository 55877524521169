import { api } from "config";
import { PATHS } from "constants/index";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useLoading } from "state/loading/hooks";
import { PaymentInfo, UserActionsData, UserData } from "types";
import { useStripe } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { useUser } from "state/user/hooks";
import axios from "axios"
export const usePaymentAndUpload = () => {
  const { isLoading, setIsLoading } = useLoading();
  const stripe = useStripe();
  const navigate = useNavigate();
  const { updateUser, user } = useUser();

  const _sendPaymentToServer = useCallback(async (paymentInfo: PaymentInfo) => {
    try{
    const { data } = await api.post("payments/transactions/", paymentInfo);
    return data;
    }
    catch(e:any){
      toast.error(e)
    }
  }, []);
  const _sendCheckoutPayment = useCallback(async (usersData: any) => {
    try{
    await api.post("payments/shipping/transactions/", usersData);
    return true;
    }
    catch(e:any){
      toast.error(e)
    }
  }, []);

  const _uploadUserData = useCallback(
    async (userData: UserData) => {
      try{
      setIsLoading(true);
      var formdata = new FormData();
      for (const [key, value] of Object.entries(userData)) {
        formdata.append(key, value);
      }
      const { data } = await api.post("upload-data/", formdata);
      setIsLoading(false);
      return data.data;
    }
    catch(e:any){
      toast.error(e);
    }
    finally{
      setIsLoading(false);
    }
  },
    [setIsLoading]
  );
  const _uploadLabData = useCallback(
    async (files: any) => {
      try{
      setIsLoading(true);
      var formdata = new FormData();
      [...files].map(file => {
        formdata.append('files',file);
      });
       await api.post("lab_file/upload/", formdata);
       toast.success("Files Upload Successfully");
    }
    catch(e:any){
      toast.error(e);
    }
    finally {
      setIsLoading(false);
    }
    },
    [setIsLoading]
  );
  const createOrderKit = useCallback(
    async (data: any) => {
      try{
      setIsLoading(true);
       await api.post("create/order/kit/", data);
      return data.data;
      }
      catch(e:any){
        toast.error(e)
      }
      finally{
        setIsLoading(false);
      }
    },
    [setIsLoading]
  );
  
  const getUserData = useCallback(async () => {
    const { data } = await api.get("user/details/");
    updateUser({ credits: data.data.credits, kit_credits:data.data.kit_credits, is_SuperAdmin:data.data.is_superuser });
  }, []);


  const processCheckoutOnly = useCallback(
    async (data: any) => {
      try {
        setIsLoading(true);
          await createOrderKit(data)
          toast.success("Order Created Successfully");
          navigate(`/order`);
      } catch (e: any) {
        toast.error(e);
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, user, navigate, updateUser]
  );
  const processFilesOnly = useCallback(
    async (userData: UserData) => {
      try {
        setIsLoading(true);
        const data = await _uploadUserData(userData);
        if (!user) return;
        if (userData.service_type === "special")
          updateUser({ credits: user.credits - 2 });
        if (userData.service_type === "normal")
          updateUser({ credits: user.credits - 1 });

        toast.success("File Upload Successfully");
        console.log("HerErer")
        navigate(`${PATHS.CATEGORIES}/${data.report_id}`);
      } catch (e: any) {
        toast.error(e);
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, _uploadUserData, navigate, user, updateUser]
  );

  const processPaymentAndUploadData = useCallback(
    async (userActionsData: UserActionsData) => {
      try {
        setIsLoading(true);
        if (stripe) {
          const result = await stripe.createToken(
            userActionsData.cardInfo.cardNumber,
            {
              name: userActionsData.cardInfo.cardHolderName,
            }
          );
          if (result.token) {
            await _sendPaymentToServer({
              service_type: userActionsData.paymentInfo.service_type,
              stripe_token: result.token.id,
            });
            const data = await _uploadUserData(userActionsData.userData);
            toast.success("File Upload Successfully");
            navigate(`${PATHS.CATEGORIES}/${data.report.entry_no}`);
          } else toast.error(result.error.message);
        } else throw new Error("Unable to connect to stripe");
      } catch (e: any) {
        toast.error(e);
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, _sendPaymentToServer, _uploadUserData, navigate, stripe]
  );
  const processCheckoutPayment = useCallback(
    async (data: any) => {
      try {
        setIsLoading(true);
        if (stripe) {
          const result = await stripe.createToken(
            data.cardNumber,
            {
              name: data.cardHolderName,
            }
          );
          if (result.token) {
            try{
            await _sendCheckoutPayment({
              first_name: data.firstName,
              last_name: data.lastName,
              client_email:data.clientEmail,
              mode_of_transaction: data.mode+"_"+data.serviceType,
              stripe_token: result.token.id,
              shipping_name: data.firstName+" "+data.lastName,
              shipping_address_line_1: data.shippingAddress1,
              shipping_address_line_2: data.shippingAddress2,
              city: data.city,
              state: data.state,
              zip_code: data.zipCode,
              country: data.country
            })
            await createOrderKit({
              first_name: data.firstName,
              last_name: data.lastName,
              client_email:data.clientEmail,
              phone: data.phoneNumber,
              mode_of_transaction: data.mode+"_"+data.serviceType,
              shipping_address_line_1: data.shippingAddress1,
              shipping_address_line_2: data.shippingAddress2,
              city: data.city,
              state: data.state,
              zip_code: data.zipCode,
              country: data.country,
              service_type:data.service_type
            })
            toast.success("Order Created Successfully");
            navigate(`/order`);
          }
          catch(e){
            toast.error("Something Went Wrong")
          }
            
          } else throw new Error(result.error.message);
        } else throw new Error("Unable to connect to stripe");
      } catch (e: any) {
        console.log(e)
        toast.error(e);
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, stripe, navigate]
  );

  return {
    getUserData,
    processPaymentAndUploadData,
    processFilesOnly,
    isLoading,
    credits: user?.credits ?? 0,
    kit_credits: user?.kit_credits ?? 0,
    processCheckoutPayment,
    processCheckoutOnly,
    _sendCheckoutPayment,
    _uploadLabData
  };
};
