import Routes from "routes/routes";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Toast from "components/Notification";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISH_KEY ?? ""
);

function App() {
  return (
    <Elements stripe={stripePromise}>
      <Routes />
      <Toast />
    </Elements>
  );
}

export default App;
