import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../../pages/login";
import { Navigate } from "react-router-dom";
import { PATHS } from "../../constants";
import ResetPassword from "pages/ResetPassword";
import ForgetPassword from "pages/ForgetPassword";
const Authroute = () => {
  return (
    <Routes>
      <Route path={PATHS.LOGIN} element={<Login />} />
      <Route path={PATHS.RESETPASSWORD} element={<ResetPassword/>} />
      <Route path={PATHS.FORGETPASSWORD} element={<ForgetPassword/>} />
      <Route path={PATHS.DEFAULT} element={<Navigate to={"/login"} />} />
    </Routes>
  );
};

export default Authroute;
