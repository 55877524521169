import React, { useState } from "react";
import Input from "../Input";
import Button from "components/Button";
import close from "assets/Icons/close.png";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
} from "@stripe/react-stripe-js";
import { CardInfo } from "types";

interface IProps {
  onClose: () => void;
  sendPaymentInfo: (cardInfo: CardInfo) => void;
  isLoading: boolean;
}

const Form: React.FC<IProps> = ({ onClose, sendPaymentInfo, isLoading }) => {
  const elements = useElements();
  const [cardName, setCardName] = useState({
    value: "",
    error: "",
  });
  //* card name handler
  const handleCardName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value)
      setCardName({
        value,
        error: "",
      });
    else
      setCardName({
        value,
        error: "Card Name is required",
      });
  };

  const handleClose = () => {
    onClose(); // Call the onClose prop to close the form
  };

  //*submit handler
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!elements || cardName.error) return;
    const cardNumber = elements.getElement(CardNumberElement);
    if (cardNumber) {
      sendPaymentInfo({
        cardHolderName: cardName.value,
        cardNumber: cardNumber,
      });
    }
  };
  return (
    <div className="fixed flex justify-center items-center top-0 left-0 w-full h-full bg-black bg-opacity-70 z-50">
      <form
        className="md:w-[50%] bg-[#FCFCFC] p-5 rounded md:shadow-md max-h-full w-[90%] lg:w-1/3"
        noValidate
        onSubmit={handleSubmit}
      >
        <button
          onClick={handleClose}
          disabled={isLoading}
          className="bg-transparent focus:outline-none float-right"
        >
          <img src={close} alt="close" className="w-6 h-6" />
        </button>
        <div className="mx-4">
          <h2 className="leading-[50px] text-xl font-bold my-5 py-2">
            Enter Card details to continue
          </h2>
          <Input
            placeholder="Name on card"
            name="cardName"
            error={cardName.error}
            onChange={handleCardName}
            value={cardName.value}
          />
          <CardNumberElement
            className="card_container"
            options={{
              showIcon: true,
              placeholder: "Card Number",
              style: {
                base: {
                  padding: "20px 0 0 0px",
                  iconColor: "#000000",
                  backgroundColor: "#EBEBEB",
                  fontWeight: "500",
                  fontFamily: "NOIRetBLANC",
                  fontSmoothing: "antialiased",
                  fontSize: "20px",
                  ":-webkit-autofill": {
                    color: "#000000",
                  },
                  "::placeholder": {
                    color: "#9CA4B0",
                  },
                },
                invalid: {
                  iconColor: "#FFC7EE",
                  color: "rgb(185 28 28 ",
                },
              },
            }}
          />

          <div className="flex justify-between flex-row space-x-4">
            <div className="w-1/2">
              <CardExpiryElement
                options={{
                  placeholder: "Expiration Date",

                  style: {
                    base: {
                      iconColor: "#000000",
                      backgroundColor: "#EBEBEB",
                      fontWeight: "500",
                      fontFamily: "NOIRetBLANC",
                      fontSmoothing: "antialiased",
                      fontSize: "20px",
                      ":-webkit-autofill": {
                        color: "#000000",
                      },
                      "::placeholder": {
                        color: "#9CA4B0",
                      },
                    },
                    invalid: {
                      iconColor: "#FFC7EE",
                      color: "rgb(185 28 28 ",
                    },
                  },
                }}
              />
            </div>
            <div className="w-1/2">
              <CardCvcElement
                options={{
                  placeholder: "CVV",
                  style: {
                    base: {
                      iconColor: "#000000",
                      backgroundColor: "#EBEBEB",
                      fontWeight: "500",
                      fontFamily: "NOIRetBLANC",
                      fontSmoothing: "antialiased",
                      fontSize: "20px",
                      ":-webkit-autofill": {
                        color: "#000000",
                      },
                      "::placeholder": {
                        color: "#9CA4B0",
                      },
                    },
                    invalid: {
                      iconColor: "#FFC7EE",
                      color: "rgb(185 28 28 ",
                    },
                  },
                }}
              />
            </div>
          </div>
          <Button
            title="Proceed"
            className="text-lg leading-normal mb-4"
            type="submit"
            isLoading={isLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default Form;
