import { createSlice } from "@reduxjs/toolkit";
import { ClientSliceType, Client, ClientDetail } from "./types";

const initialState: ClientSliceType = {
  coachClients: [],
  clientDetails: [],
  selectedClient: undefined,
};

const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    setCoachClientsDataStateAction: (
      state,
      action: {
        payload: Client[];
      }
    ) => ({
      ...state,
      coachClients: action.payload,
    }),
    setClientsDetailsStateAction: (
      state,
      action: {
        payload: ClientDetail[];
      }
    ) => ({
      ...state,
      clientDetails: action.payload,
    }),
    setSelectedClientStateAction: (
      state,
      action: {
        payload: string | undefined;
      }
    ) => ({
      ...state,
      selectedClient: action.payload,
    }),
   
  },
});

export const {
  setCoachClientsDataStateAction,
  setClientsDetailsStateAction,
  setSelectedClientStateAction,
  } = clientsSlice.actions;

export default clientsSlice.reducer;
