import { useModal } from "context/ModalProvider";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
const Modal = () => {
  const { isModalOpen: isOpen, handleClose, handleDelete } = useModal();
  const navigate = useNavigate()
  return ReactDOM.createPortal(
    <div
      className={`fixed flex justify-center items-center top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 ${
        isOpen ? "visible opacity-1" : "opacity-0 invisible"
      }`}
    >
      <div className="relative bg-[#FCFCFC] p-8 max-w-[600px] rounded-md text-center flex flex-col gap-y-10">
        <h1 className="text-primary text-3xl font-sans font-medium">
          Are you sure you want to delete this report?
        </h1>
        <div className="flex justify-center gap-x-4">
          <button
            className=" rounded-md px-10 py-2 text-xl bg-[#d9d9d9] transition-all duration-200 hover:bg-[#c4c4c4] font-sans font-medium"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="rounded-md px-10 py-3 text-xl bg-main transition-all duration-200 hover:main font-sans font-medium"
            onClick={()=>handleDelete(navigate)}
          >
            Delete
          </button>
        </div>
      </div>
    </div>,
    document.getElementById("modal") as HTMLElement
  );
};

export default Modal;
