import { createContext, useCallback, useContext, useState } from "react";
import { useReportRecords } from "hooks/useReportRecords";
type TModalContext = {
  isModalOpen: boolean;
  handleClose: () => void;
  handleDelete: (navigate:any) => void;
  handleOpen: (resourceID: string) => void;
  selectedResource: string | undefined;
};
type TModalProvider = {
  children: React.ReactNode;
};

const ModalContext = createContext<TModalContext>({
  isModalOpen: false,
  handleClose: () => {},
  handleDelete: () => {},
  handleOpen: (resourceID: string) => {},
  selectedResource: undefined,
});

const ModalProvider = ({ children }: TModalProvider) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedResource, setSelectedResource] = useState<
    string | undefined
  >();
  const { deleteReport } = useReportRecords();
  const handleClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);
  const handleOpen = (resourceID: string) => {
    setIsModalOpen(!isModalOpen);
    setSelectedResource(resourceID);
  };
  const handleDelete = async(navigate:any) => {
    if (selectedResource) {
      await deleteReport(navigate,selectedResource)
      setIsModalOpen(false);
    }
  };

  return (
    <ModalContext.Provider
      value={{
        isModalOpen,
        handleClose,
        selectedResource,
        handleOpen,
        handleDelete,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
export const useModal = () => {
  return useContext(ModalContext);
};
