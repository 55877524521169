import Button from "components/Button";
import { useFormik } from "formik";
import { useState } from "react";
import { usePaymentAndUpload } from "hooks/usePaymentAndUpload";
import { LABFILEUPLOADVALUES } from "constants/index";
import { labFileUploadSchema } from "utils/validationSchema";
import FileInput from "./FileInput";
const Form = () => {
    const { _uploadLabData, isLoading } = usePaymentAndUpload();
    const [selectedFile, setSelectedFile] = useState<FileList | null>(null);
    const {
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
        setFieldValue,
    } = useFormik({
        initialValues: LABFILEUPLOADVALUES,
        validationSchema: labFileUploadSchema,
        onSubmit: async (values) => {
            await _uploadLabData(selectedFile)
            setSelectedFile(null)
        },
    });
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setSelectedFile(event.target.files);
            setFieldValue("inputFile", event.target.files.length);
        }
    };
    return (
        <div>
            <form
                onSubmit={handleSubmit}
                className={`max-w-[800px] min-w-[400px] font-sans bg-[#FCFCFC] p-10 rounded md:shadow-md  justify-center items-center`}
                noValidate
            >
                <h2 className="text-xl mt-5 mb-10 leading-normal">
                    Upload Reports
                </h2>
                <div>
                    <FileInput
                        onChange={handleFileChange}
                        selectedFile={selectedFile}
                        errors={touched.inputFile && errors.inputFile}
                    />
                </div>
                <Button
                    title="Submit"
                    className={`text-xl leading-normal`}
                    type="submit"
                    isLoading={isLoading}
                />
            </form>
        </div>
    );
};

export default Form;
