
import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="bg-main">
      <footer className="flex justify-center items-end m-0">
        <div className="flex md:flex-wrap justify-center md:space-x-32 py-3">
          <Link
            to="/"
            className="text-gray-900 text-[18px] mx-2 md:w-auto text-center"
          >
            Privacy Policy
          </Link>
          <Link
            to="/"
            className="text-gray-900 text-[18px] mx-2 md:w-auto text-center"
          >
            Terms of Use
          </Link>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
