import { useEffect } from "react"
import Form from "./components/Form"
const UpdateKitDetails = () => {
    useEffect(()=>{
        window.location.href="https://thedesignergenesco.com/register"
    },[])
    return (
        <>
        </>
        // <div className="bg-main">
        //     <div className="flex flex-col sm:pt-12 mx-4 items-center justify-center pt-2">
        //         <div className="mt-8">
        //             <Form />
        //         </div>
        //     </div>
        // </div>
    )
}
export default UpdateKitDetails