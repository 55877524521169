import { useCallback } from "react";
import { useLoading } from "state/loading/hooks";
import { useOrderKits } from "state/orderKits/hooks";
import { toast } from "react-toastify";
import { api } from "config";
export const useOrderKitsData = () => {
    const { setOrderKitsData, orderKitsData } = useOrderKits()
    const { setIsLoading } = useLoading()
    const fetchOrderKits = useCallback(
        async () => {
            try {
                setIsLoading(true);
                const { data } = await api.get("coach/kit/clients/");
                if (data.success) {
                    setOrderKitsData(data.data);
                    console.log(data.data)
                }

            } catch (e: any) {
                toast.error(e);
            } finally {
                setIsLoading(false);
            }
        },
        [setIsLoading, setOrderKitsData]
    );
    const updateOrderKitStatus = useCallback(
        async () => {
            try {
                setIsLoading(true);
                await api.get("create/order/kit/");
            } catch (e: any) {
                toast.error(e);
            } finally {
                setIsLoading(false);
            }
        },
        [setIsLoading, setOrderKitsData]
    );
    return {
        fetchOrderKits,
        orderKitsData,
        updateOrderKitStatus
    }
}
