import React from 'react'

interface IProps {
  text: string;
}
const Text :React.FC<IProps>= ({text}) => {
  return (
    <div>
      <p className="mb-[30px] mt-[20px] text-lg w-full bg-[#FCFCFC] font-helvetica p-5">
       {text}
      </p>
    </div>
  );
}

export default Text
