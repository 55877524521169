import React from 'react';
import AuthLayout from "components/AuthLayout";
import UpdatePasswordForm from './components/Form';  // Import the Form component

const UpdatePasswordPage: React.FC = () => {
    return (
        <AuthLayout className="w-[400px] p-4 max-h-[650px] h-full flex items-center justify-center">
            <UpdatePasswordForm />  {/* Render the password update form */}
        </AuthLayout>
    );
};

export default UpdatePasswordPage;
