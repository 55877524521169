import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "state/store";
import {
  setClientsDetailsStateAction,
  setCoachClientsDataStateAction,
  setSelectedClientStateAction,
} from ".";
import { ClientSliceType, Client, ClientDetail } from "./types";
import { useCallback } from "react";

export const useClients = () => {
  const { coachClients, clientDetails, selectedClient } = useSelector<
    RootState,
    ClientSliceType
  >((state) => state.clients);
  const dispatch = useAppDispatch();

  const setCoachClients = useCallback(
    (payload: Client[]) => {
      dispatch(setCoachClientsDataStateAction(payload));
    },
    [dispatch]
  );
  const setClientDetails = useCallback(
    (payload: ClientDetail[]) => {
      dispatch(setClientsDetailsStateAction(payload));
    },
    [dispatch]
  );
  const setSelectedClient = useCallback(
    (payload: string | undefined) => {
      dispatch(setSelectedClientStateAction(payload));
    },
    [dispatch]
  );

  return {
    coachClients,
    setCoachClients,
    clientDetails,
    setClientDetails,
    selectedClient,
    setSelectedClient,
  
  };
};
