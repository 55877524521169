import { useState, useCallback, useMemo } from "react";
import Input from "components/Input";
import Button from "components/Button";
import { useFormik } from "formik";
import { CHECKOUTVALUES } from "constants/index";
import { checkoutSchema } from "utils/validationSchema";
import CheckboxGroup from "../../../components/RadioButton/RadioButton";
import Form from "components/Stripe/Form";
import Select from "components/Select";
import { FormOptions } from "types";
import { FORMOPTIONS } from "constants/index";
type IProps = {
  processCheckoutPayment: (arg: any) => void;
  isLoading: boolean,
  credits: number;
  getUserData: () => void;
  processCheckoutOnly: (arg: any) => void;
};
const Checkout = ({ processCheckoutPayment, isLoading, credits, processCheckoutOnly, getUserData }: IProps) => {
  const [open, setOpen] = useState(false);
  const [selectForm, setSelectForm] = useState<FormOptions>(
    FormOptions["Alabama"]
  );
  const shippingOptions = [
    { label: "Domestic Shipping ($10)", value: "domestic" },
    { label: "International Shipping ($30)", value: "international" },
  ];
  const reportOptions = [
    { label: "Core Report (Nutrition & Longevity) $375", value: "normal" },
    { label: "Complete Report (All Panels) $500", value: "special" },
  ];
  useMemo(() => {
    getUserData()
  }, []);
  const { handleSubmit, handleChange, values, touched, errors, setFieldValue } =
    useFormik({
      initialValues: CHECKOUTVALUES,
      validationSchema: checkoutSchema,
      onSubmit: () => {
        if (credits === 0) setOpen(true)
        else {
          processCheckoutOnly({
            first_name: values.firstName,
            last_name: values.lastName,
            client_email: values.clientEmail,
            phone: values.phoneNumber,
            mode_of_transaction: values.mode+"_"+values.serviceType,
            shipping_name: values.firstName + " " + values.lastName,
            shipping_address_line_1: values.shippingAddress1,
            shipping_address_line_2: values.shippingAddress2,
            city: values.city,
            state: values.state,
            zip_code: values.zipCode,
            country: values.country,
            service_type:values.serviceType
          })
        }
      },
    })
  const handleCloseForm = () => {
    setOpen(false);
  };
  const completeUserWorkflow = useCallback(
    (cardData: any): void => {
      const data = {
        ...values,
        cardHolderName: cardData.cardHolderName,
        cardNumber: cardData.cardNumber,
      }
      processCheckoutPayment(data)
    },
    [processCheckoutPayment, values]
  );
  return (
    <div>
<div data-thrivecart-account="designergenes" data-thrivecart-tpl="v2" data-thrivecart-product="1" className="thrivecart-embeddable" data-thrivecart-embeddable="tc-designergenes-1-I5Z067"></div>
      {/* <form
        className={`max-w-[800px] min-w-[400px] font-sans bg-[#FCFCFC] p-10 rounded md:shadow-md  justify-center items-center ${open && "filter blur"
          }`}
        noValidate
        onSubmit={handleSubmit}
      >
        <h2 className="text-xl mt-5 mb-10 leading-normal">
          Enter your Shipping Details
        </h2>
        <div>
          <Input
            type="text"
            name="clientEmail"
            className="mb-4 px-3 font-sans mt-2"
            value={values.clientEmail}
            placeholder="Client Email"
            onChange={handleChange}
            error={touched.clientEmail && errors.clientEmail}
          />
          <Input
            type="text"
            name="firstName"
            className="mb-4 px-3 font-sans mt-2"
            value={values.firstName}
            placeholder="First Name"
            onChange={handleChange}
            error={touched.firstName && errors.firstName}
          />
          <Input
            type="text"
            name="lastName"
            className="mb-4 px-3 font-sans mt-2"
            value={values.lastName}
            placeholder="Last Name"
            onChange={handleChange}
            error={touched.lastName && errors.lastName}
          />
          <Input
            type="text"
            name="phoneNumber"
            className="mb-4 px-3 font-sans mt-2"
            value={values.phoneNumber}
            placeholder="Phone Number"
            onChange={handleChange}
            error={touched.phoneNumber && errors.phoneNumber}
          />
          <Input
            type="text"
            name="shippingAddress1"
            className="mb-4 px-3 font-sans mt-2"
            value={values.shippingAddress1}
            placeholder="Shipping Address Line 1"
            onChange={handleChange}
            error={touched.shippingAddress1 && errors.shippingAddress1}
          />
          <Input
            type="text"
            name="shippingAddress2"
            className="mb-4 px-3 font-sans mt-2"
            value={values.shippingAddress2}
            placeholder="Shipping Address Line 2"
            onChange={handleChange}
            error={touched.shippingAddress2 && errors.shippingAddress2}
          />
          <Input
            type="text"
            name="city"
            className="mb-4 px-3 font-sans mt-2"
            value={values.city}
            placeholder="City"
            onChange={handleChange}
            error={touched.city && errors.city}
          />
          {values.mode === "international" ? (
            <Input
              type="text"
              name="state"
              className="mb-4 px-3 font-sans mt-2"
              value={values.state}
              placeholder="State"
              onChange={handleChange}
              error={touched.state && errors.state}
            />
          ) : (
            <Select
              onChange={handleChange}
              className="mb-2 font-sans mt-2"
              options={FORMOPTIONS}
              value={selectForm}
              onClick={(value: string) => {
                setSelectForm(value as FormOptions);
                setFieldValue("state", value);

              }}

            />
          )}

          <Input
            type="text"
            name="zipCode"
            className="mb-4 px-3 font-sans mt-2"
            value={values.zipCode}
            placeholder="Zip Code"
            onChange={handleChange}
            error={touched.zipCode && errors.zipCode}
          />
          <div className="mb-5">
            <CheckboxGroup
              options={reportOptions}
              onChange={handleChange}
              name="serviceType"
              error={touched.serviceType && errors.serviceType?.toString()}
              value={
                values.serviceType
                  ? values.serviceType.toString()
                  : values.serviceType
              }
              className="font-sans"
            />
          </div>
          <CheckboxGroup
            options={shippingOptions}
            onChange={(e) => {
              if (e.target.value === "international") {
                setFieldValue("state", "");
                setFieldValue("country", "");
              }
              else {
                setFieldValue("country", "US");
                setFieldValue("state", FORMOPTIONS[0]);
              }
              handleChange(e);
            }}
            name="mode"
            error={touched.mode && errors.mode?.toString()}
            value={values.mode || ""}
            className="font-sans"
          />
          {values.mode === "international" && (
            <Input
              type="text"
              name="country"
              className="mb-4 font-sans mt-2"
              value={values.country}
              placeholder="Country"
              onChange={handleChange}
              error={touched.country && errors.country}
            />
          )}
        </div>

        <Button
          title="Submit"
          isLoading={isLoading}
          className={`text-xl leading-normal`}
          type="submit"
        />
      </form>
      {open && (
        <Form
          onClose={handleCloseForm}
          sendPaymentInfo={completeUserWorkflow}
          isLoading={isLoading}
        />
      )} */}
    </div>
  );
}
export default Checkout