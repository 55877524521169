import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "state/store";
import { setReportDataStateAction, setSelectedCategoryStateAction,setReportSummaryStateAction,setReportStateAction} from ".";
import { RecordsSliceType, ReportData } from "./types";
import { useCallback } from "react";

export const useRecords = () => {
  const { reportData, selectedCategory, reportSummary, report } = useSelector<
    RootState,
    RecordsSliceType
  >((state) => state.records);
  const dispatch = useAppDispatch();

  const setReportData = useCallback(
    (payload: ReportData) => {
      dispatch(setReportDataStateAction(payload));
    },
    [dispatch]
  );
  const setReportSummary = useCallback(
    (payload: string) => {
      dispatch(setReportSummaryStateAction(payload));
    } ,[dispatch])
  const setSelectedCategory = useCallback(
    (payload: string | undefined) => {
      dispatch(setSelectedCategoryStateAction(payload));
    },
    [dispatch]
  );
  const setReport = useCallback(
    (payload: string | undefined) => {
      dispatch(setReportStateAction(payload));
    },
    [dispatch]
  )
 ;

  return {
    reportData,
    selectedCategory,
    setSelectedCategory,
    setReportSummary,
    setReportData,
    reportSummary,
    report,
    setReport
    

  };
};
