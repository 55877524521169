import {useEffect, useState} from "react";
import {Location, useLocation} from "react-router-dom";

function ScrollToTop() {
    const [currentLocation, setCurrentLocation] = useState<Location>();
    const location = useLocation();
    useEffect(() => {
        if (currentLocation?.pathname === location.pathname) return;
        window.scrollTo(0, 0);
        setCurrentLocation(location);
    }, [currentLocation?.pathname, location]);
}

export default ScrollToTop;