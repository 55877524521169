import Form from "./components/Form"
const LabReportsUpload = () => {
    return (
    <div className="bg-main">
        <div className="flex flex-col sm:pt-12 mx-4 items-center justify-center pt-2">
            <div className="mt-8">
                <Form />
            </div>
        </div>
    </div>
        )
}
export default LabReportsUpload